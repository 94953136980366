import React from 'react'
import { Accordion, Panel } from 'baseui/accordion'
import { useStyletron } from 'baseui'

const Faq = () => {
  const [css] = useStyletron()

  return (
    <div className={css({ marginBottom: '20px', minHeight: '300px' })}>
      <Accordion renderAll>
        <Panel title="¿Los enlaces generados tienen algún costo?">
          Los links que creas con el generador en crear.wa.link son totalmente{' '}
          <strong> gratuitos. </strong>
          <br />
          <br />
          También puedes acceder a Walink Premium y{' '}
          <strong>crear links personalizados por 6 USD anuales </strong> que
          inclyen más funcionalidades y características como URL personalizada,
          analítica y panel administrativo para que modifiques tus links.{' '}
          <a
            href="https://app.wa.link/register"
            target="_blank"
            rel="noopener noreferrer"
            alt="Register Walink"
          >
            Regístrarte para obtener tu primer link premium aquí.
          </a>
        </Panel>
        <Panel title="¿Qué es el saludo del usuario?">
          <strong>
            Cuando un usuario hace clic en el enlace, él o ella podrá enviarte
            este mensaje como saludo/inicio de la conversación
          </strong>
          . A cada link que creas puedes adicionarle este mensaje para facilitar
          el inicio de la conversación o saber desde cuál enlace específico te
          están escribiendo.
          <br />
          <br />
          <strong>
            Este mensaje NO es un saludo automático para tus usuarios
          </strong>
          . Es solo un saludo que ellos te podrán enviar ti.
          <br />
          <br />
          Revisa la vista previa cuando creas tu link.
        </Panel>
        <Panel title="¿Cómo agregar el link de WhatsApp (wa.link) al perfil de Instagram?">
          Una de las ubicaciones más comunes para usar tus links de WhatsApp
          generados con Walink, es el perfil de Instagram. Ten en cuenta que
          esta red social sólo admite 1 link en la bio, así que si ya tienes un
          link allí, tendrás que reemplazarlo.
          <br />
          <br />
          Ve a tu perfil de Instagram, luego haz clic en "Editar perfil", y en
          el espacio para "Sitio web" debes pegar el wa.link que acabas de
          hacer.
          <br />
          <br />
          Recuerda que si no lo pones en el campo de "Sitio Web", tus usuarios
          no podrán hacer clic y perderás la posibilidad de un contacto
          inmediato con tus clientes.
        </Panel>
        <Panel title="¿Por qué aparece una ventana preguntando si quiero enviar el mensaje?">
          En ocasiones, especialmente en el ordenador, cuando los usuarios hacen
          clic en el enlace el navegador le pregunta si "Desea enviar un mensaje
          al número ###-###-###". Este es el funcionamiento normal de los links
          y es la forma en la que WhatsApp le da control a los usuarios. Si esto
          te ocurre, ten la tranquilidad que tu enlace está correcto.
        </Panel>
        <Panel title="¿Puedo tener un link con URL personalizada?">
          Las URLs de los links generados de forma gratuita se asignan de
          aleatoriamente usando caracteres de letras y números. Si deseas crear
          enlaces personalizados como <strong>wa.link/TuMarca</strong>, puedes
          registrarte en{' '}
          <a
            href="https://app.wa.link/register"
            target="_blank"
            rel="noopener noreferrer"
            alt="Register Walink"
          >
            Walink Premium
          </a>{' '}
          para recibir todos los beneficios que esto trae, entre ellos las URL
          personalizadas.
        </Panel>
        <Panel title="¿Puedo crear un link para el catálogo de WhatsApp Business?">
          Los links creados con el generador gratuito son un acceso directo a
          una conversación de WhatsApp y no están habilitados para ser asignados
          al catálogo del negocio.
          <br />
          <br />
          Si deseas crear un link personalizado como{' '}
          <strong>wa.link/MiCatalogo</strong> que lleve directamente a tu
          catálogo de WhatsApp Business puedes hacerlo{' '}
          <a
            href="https://app.wa.link/register"
            target="_blank"
            rel="noopener noreferrer"
            alt="Register Walink"
          >
            registrándote en Walink Premium
          </a>{' '}
          para recibir todos los beneficios que esto trae, incluyendo los links
          al catálogo.
        </Panel>
        <Panel title="¿Puedo saber cuántas personas han hecho clic en mis enlaces?">
          Los enlaces que proveemos de forma gratuita no cuentan con opciones de
          analítica, por lo que no puedes saber cuántos clics has tenido en
          determinado periodo de tiempo.
          <br />
          <br />
          <strong>Walink Premium </strong> sí cuenta con ésta y muchas otras
          funcionalidades.{' '}
          <a
            href="https://app.wa.link/register"
            target="_blank"
            rel="noopener noreferrer"
            alt="Register Walink"
          >
            Te invitamos a registrarte aquí para recibir todos los beneficios{' '}
          </a>
          , entre ellos, la analítica de tus links Premium.
        </Panel>
        <Panel title="¿Cómo se si Walink funciona en mi país?">
          Walink funciona en todos los países en los que WhatsApp está
          disponible de forma oficial.
        </Panel>

        <Panel title="¿Puedo eliminar un wa.link?">
          Si recientemente has creado un wa.link con información incorrecta o
          simplemente no lo usarás, no tienes que eliminarlo.{' '}
          <strong>
            Simplemente crea un nuevo link con la nueva información{' '}
          </strong>{' '}
          y no uses/compartas el anterior.
          <br />
          <br />
          Si por alguna razón debes eliminar el wa.link para que no esté
          disponible al público, puedes hacerlo completando tus{' '}
          <a
            href="https://walink.io/request-delete-link"
            target="_blank"
            rel="noopener noreferrer"
            alt="delete link"
          >
            datos aquí.
          </a>{' '}
          Ten en cuenta que para eliminar el wa.link debes probar que eres el
          dueño del número telefónico asociado al link.
        </Panel>
        <Panel title="¿Si tengo algún problema con la herramienta cómo lo reporto?">
          Los enlaces generados de forma gratuita no cuentan con soporte
          personalizado. Si te encuentras con algún inconveniente puedes
          escribirnos a{' '}
          <a
            href="https://x.com/walink_inc"
            target="_blank"
            rel="noopener noreferrer"
            alt="@walink_inc"
          >
            @walink_inc
          </a>{' '}
          en X para reportar el problema.
          <br />
          <br />
          Una de las características de <strong>Walink Premium</strong> es el
          soporte personalizado para los links Premium, por lo que te invitamos
          a{' '}
          <a
            href="https://app.wa.link/register"
            target="_blank"
            rel="noopener noreferrer"
            alt="Register Walink"
          >
            {' '}
            registrarte para recibir todos los beneficios que esto trae.
          </a>
        </Panel>
      </Accordion>
    </div>
  )
}

export default Faq
