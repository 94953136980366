import React, { useState } from 'react'
import { ProgressSteps, NumberedStep } from 'baseui/progress-steps'
import { Button, SHAPE } from 'baseui/button'
import { Paragraph4 } from 'baseui/typography'
import { useStyletron } from 'baseui'

const Steps = ({ changeFocus }) => {
  const [current, setCurrent] = useState(0)
  const [css] = useStyletron()

  const handleStepChange = (step, focusElement) => {
    if (step) setCurrent(step)
    if (focusElement) changeFocus(focusElement)
  }

  return (
    <div
      className={css({
        maxWidth: '450px',
        height: '100%',
      })}
    >
      <h2 style={{ textAlign: 'center', color: '#fff' }}>
        Cómo crear tu link de WhatsApp
      </h2>
      <ProgressSteps
        current={current}
        overrides={{
          Root: {
            style: {
              borderTopRightRadius: '20px',
              borderTopLeftRadius: '20px',
            },
          },
        }}
      >
        <NumberedStep title="Escribe tu número de WhatsApp">
          <Paragraph4>
            Escribe el número con el cual quieres que te contacten. Recuerda
            verificar el código de tu país.
          </Paragraph4>
          <Button
            shape={SHAPE.pill}
            size="compact"
            onClick={() => handleStepChange(1)}
            overrides={{
              BaseButton: {
                style: {
                  boxShadow: 'rgba(0, 0, 0, 0.3) 0px 1px 4px',
                },
              },
            }}
          >
            Siguiente
          </Button>
        </NumberedStep>
        <NumberedStep title="Escribe un mensaje con el que te saludarán">
          <Paragraph4>
            Este mensaje es opcional, pero lo recomendamos para dar
            personalización a tu enlace. Por ejemplo: “Hola, quiero más
            información”
          </Paragraph4>
          <Button
            shape={SHAPE.pill}
            size="compact"
            onClick={() => handleStepChange(2)}
            overrides={{
              BaseButton: {
                style: {
                  boxShadow: 'rgba(0, 0, 0, 0.3) 0px 1px 4px',
                },
              },
            }}
          >
            Siguiente
          </Button>
        </NumberedStep>
        <NumberedStep title="Haz clic en Generar mi wa.link, cópialo y ¡úsalo donde quieras!">
          <Paragraph4>
            El generador te regala un enlace corto y un código QR que puedes
            descargar.
          </Paragraph4>
          <Button
            shape={SHAPE.pill}
            size="compact"
            onClick={() => handleStepChange(null, 'phone')}
            overrides={{
              BaseButton: {
                style: {
                  boxShadow: 'rgba(0, 0, 0, 0.3) 0px 1px 4px',
                },
              },
            }}
          >
            ¡Empecemos!
          </Button>
        </NumberedStep>
      </ProgressSteps>
    </div>
  )
}

export default Steps
