import React from 'react'
import { FiExternalLink } from 'react-icons/fi'
import { Button, SHAPE } from 'baseui/button'
import Emoji from './Emoji'
import { useStyletron } from 'baseui'

const PricingBanner = () => {
  const [css] = useStyletron()

  const ctaContainer = css({
    width: '100%',
    boxShadow: 'rgba(0, 0, 0, 0.3) 0px 1px 4px',
    borderRadius: '15px',
    // background: 'linear-gradient(315deg, #1fd1f9 0%, #b621fe 74%)',
    background: 'linear-gradient(-45deg, #23a6d5, #23d5ab)',
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'wrap',
    // justifyContent: 'center',
    color: '#ffff',
  })

  const goToApp = () => {
    window.location.href = 'https://app.wa.link/register'
  }

  return (
    <div className={ctaContainer}>
      <div
        className={css({
          width: '100%',
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          flexWrap: 'wrap',
          // paddingTop: '20px',
          // paddingBottom: '20px',
          paddingLeft: '20px',
          paddingRight: '50px',

          '@media screen and (max-width: 660px)': {
            textAlign: 'center',
            paddingRight: '20px',
            justifyContent: 'center',
          },
        })}
      >
        <h3
          className={css({
            fontSize: '2rem',
          })}
        >
          <Emoji symbol={'👑'} size="30px" /> ¿Quieres ser Premium?
        </h3>

        <div>
          <Button
            shape={SHAPE.pill}
            onClick={goToApp}
            overrides={{
              BaseButton: {
                style: {
                  boxShadow: 'rgba(0, 0, 0, 0.3) 0px 1px 4px',
                  marginBottom: '20px',
                  marginTop: '20px',
                },
              },
            }}
          >
            ¡Regístrate ya!{' '}
            <span style={{ marginLeft: '5px' }}>
              <FiExternalLink />
            </span>
          </Button>
        </div>
      </div>
    </div>
  )
}

export default PricingBanner
