import React, { useState } from 'react'
import { Link } from 'gatsby'
import { H2, Paragraph2, H3 } from 'baseui/typography'
import { useStyletron } from 'baseui'
import Layout from '../components/layout'
import GeneratorWrapper from '../components/GeneratorWrapper'
import Faq from '../components/faq'
import SEO from '../components/seo'
import Steps from '../components/steps'
import Hero from '../components/hero'
import CTA from '../components/RegisterCTA'
import Pricing from '../components/Pricing'
import Emoji from '../components/Emoji'
import PricingBanner from '../components/PricingBanner'
import { FiExternalLink, FiArrowDown } from 'react-icons/fi'
import { StatefulPopover, TRIGGER_TYPE, PLACEMENT } from 'baseui/popover'
import scrollTo from 'gatsby-plugin-smoothscroll'
import { isMobile } from 'react-device-detect'
import SearchLink from '../components/SearchLink'

const IndexPage = () => {
  const [css] = useStyletron()
  const [focusElement, setFocusElement] = useState('')

  const centerText = css({
    display: 'flex',
    justifyContent: 'center',
  })

  const actionCard = css({
    display: 'flex',
    justifyContent: 'center',
    boxShadow: 'rgba(0, 0, 0, 0.2) 0px 1px 4px',
    // padding: '10px',
    paddingLeft: '10px',
    paddingRight: '10px',
    borderRadius: '15px',
    margin: '15px',
    minWidth: '250px',
    textDecoration: 'none',
    backgroundColor: '#fff',
  })

  return (
    <Layout hero={<Hero />} page="home">
      <SEO
        title="Crea links de WhatsApp"
        hreflang={[
          { rel: 'alternate', hrefLang: 'es', href: 'https://crear.wa.link' },
          { rel: 'alternate', hrefLang: 'en', href: 'https://create.wa.link' },
          { rel: 'alternate', hrefLang: 'pt', href: 'https://criar.wa.link' },
          {
            rel: 'alternate',
            hrefLang: 'x-default',
            href: 'https://create.wa.link',
          },
        ]}
      />
      {/* From here */}
      <div
        className={css({
          marginTop: '3rem',
          paddingBottom: '3rem',
          borderBottom: '1px solid rgba(0, 0, 0, 0.1)',
        })}
      >
        <h2
          className={css({
            textAlign: 'center',
            marginBottom: '2rem',
          })}
        >
          ¿Qué quieres hacer hoy?
        </h2>
        <div
          className={css({
            display: 'flex',
            justifyContent: 'center',
            flexWrap: 'wrap',
            backgroundColor: 'rgb(238, 238, 238);',
            paddingTop: '1rem',
            paddingBottom: '1rem',
            borderRadius: '15px',
          })}
        >
          <ActionPopover content="Crea links de WhatsApp con mensaje personalizado, ¡gratis!">
            <a
              href="/"
              style={{}}
              onClick={e => {
                e.preventDefault()
                scrollTo('#crear-link')
              }}
              className={actionCard}
            >
              <p>
                <Emoji symbol={'😁'} /> Crear un wa.link gratis
                <span style={{ marginLeft: '5px' }}>
                  <FiArrowDown style={{ verticalAlign: 'middle' }} />
                </span>
              </p>
            </a>
          </ActionPopover>
          <ActionPopover
            content={
              <>
                Por <strong>USD $6/año</strong> obtienes un wa.link
                personalizado con información editable, analítica, buscador y
                mucho más...
              </>
            }
          >
            <a
              href="#premium"
              style={{ textDecoration: 'none' }}
              className={actionCard}
              onClick={e => {
                e.preventDefault()
                scrollTo('#premium')
              }}
            >
              <p>
                <Emoji symbol={'👑'} /> wa.link personalizado
                <span style={{ marginLeft: '5px' }}>
                  <FiArrowDown style={{ verticalAlign: 'middle' }} />
                </span>
              </p>
            </a>
          </ActionPopover>
          <ActionPopover content="Abre conversaciones de WhatsApp sin guardar contactos">
            <a
              href="https://open.wa.link"
              style={{ textDecoration: 'none' }}
              className={actionCard}
            >
              <p>
                <Emoji symbol={'💬'} /> Abrir chats de WhatsApp
                <span style={{ marginLeft: '5px' }}>
                  <FiExternalLink size="16" />
                </span>
              </p>
            </a>
          </ActionPopover>
        </div>
      </div>
      <div
        className={css({
          // margin: '4rem 0',
          margin: '3rem 0 4rem 0',
        })}
      >
        <h2
          className={css({
            textAlign: 'center',
          })}
        >
          ¿Por qué crear links de WhatsApp con Walink?
        </h2>
        <div className={centerText}>
          <Paragraph2>
            Para abrir un chat en WhatsApp, por lo general, tienes que saber el
            número de teléfono del usuario, guardarlo en el dispositivo y luego
            buscarlo para iniciar la conversación. Un link de WhatsApp resume
            todo este procedimiento en 1 clic que abre el chat sin necesidad de
            guardar números, a esto se le llama{' '}
            <strong>“clic para chatear”</strong>.
          </Paragraph2>
        </div>
        <div className={centerText}>
          <Paragraph2>
            Si lo que buscas es crear links de WhatsApp para que las personas te
            contacten de forma instantánea, estás en el lugar indicado. Con
            Walink puedes generar links de WhatsApp de forma gratuita con
            <strong> dominio wa.link</strong> y <strong>código QR</strong> en
            menos de 1 minuto, sin registrarte.
          </Paragraph2>
        </div>
        <div className={centerText}>
          <Paragraph2>
            Además podrás tener links oficiales de tu negocio al suscribirte a
            Walink Premium y crear links personalizados de WhatsApp como{' '}
            <strong>wa.link/MiNegocio</strong> que llevarán muchos más clientes
            al chat, con analítica de clics, información editable, multiagente,
            links con formulario y mucho más.
          </Paragraph2>
        </div>
      </div>
      <div
        className={css({
          display: 'flex',
          justifyContent: 'center',
          margin: '50px auto',
          background: 'linear-gradient(-45deg, #23a6d5, #23d5ab)',
          minHeight: '310px',
          boxShadow: 'rgba(0, 0, 0, 0.16) 0px 1px 4px',
          borderRadius: '10px',
        })}
      >
        <Steps changeFocus={setFocusElement} />
      </div>
      <div
        id="crear-link"
        className={css({
          paddingTop: '10px',
        })}
      >
        <H2 className={css({ textAlign: 'center' })}>
          Crea tu link gratis aquí
        </H2>
        <GeneratorWrapper focusElement={focusElement} />
      </div>
      <div
        className={css({ marginTop: '3rem', marginBottom: '1rem' })}
        id="premium"
      >
        <H3 className={css({ textAlign: 'center' })}>
          Encuentra un wa.link para tu marca
        </H3>
        <SearchLink showPremium={true} />
      </div>
      <div className={css({ marginTop: '60px', marginBottom: '60px' })}>
        <Pricing />
        <PricingBanner />
      </div>
      <div className={css({ marginTop: '50px' })}>
        <div className={css({ margin: '20px' })}>
          <H2>Preguntas frecuentes:</H2>
        </div>
        <Faq />
        <CTA
          title="¿Quieres un link personalizado?"
          description={
            <>
              Con{' '}
              <Link
                to="/premium"
                style={{
                  color: '#fff',
                  textDecoration: 'underline',
                  fontWeight: 'bold',
                }}
              >
                Walink Premium
              </Link>{' '}
              puedes crear, medir y modificar tus links personalizados de
              WhatsApp.
              <strong> (wa.link/TuMarca)</strong>
            </>
          }
          currentPage="Home"
        />
      </div>
    </Layout>
  )
}

function ActionPopover({ children, content }) {
  return (
    <>
      {isMobile ? (
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          {children}

          <StatefulPopover
            showArrow
            content={<Paragraph2 padding="scale300">{content}</Paragraph2>}
            accessibilityType={'tooltip'}
            triggerType={TRIGGER_TYPE.click}
            placement={PLACEMENT.bottom}
            overrides={{
              Body: {
                style: {
                  width: '250px',
                  textAlign: 'center',
                  marginTop: '10px',
                  borderTopLeftRadius: '15px',
                  borderBottomLeftRadius: '15px',
                  borderTopRightRadius: '15px',
                  borderBottomRightRadius: '15px',
                },
              },
              Inner: {
                style: {
                  borderTopLeftRadius: '15px',
                  borderBottomLeftRadius: '15px',
                  borderTopRightRadius: '15px',
                  borderBottomRightRadius: '15px',
                },
              },
            }}
          >
            <span
              style={{
                verticalAlign: 'middle',
                marginRight: '5px',
                fontStyle: 'italic',
              }}
            >
              <strong>i</strong>
            </span>
          </StatefulPopover>
        </div>
      ) : (
        <StatefulPopover
          showArrow
          content={<Paragraph2 padding="scale300">{content}</Paragraph2>}
          accessibilityType={'tooltip'}
          triggerType={TRIGGER_TYPE.hover}
          placement={PLACEMENT.bottom}
          overrides={{
            Body: {
              style: {
                width: '250px',
                textAlign: 'center',
                marginTop: '10px',
                borderTopLeftRadius: '15px',
                borderBottomLeftRadius: '15px',
                borderTopRightRadius: '15px',
                borderBottomRightRadius: '15px',
              },
            },
            Inner: {
              style: {
                borderTopLeftRadius: '15px',
                borderBottomLeftRadius: '15px',
                borderTopRightRadius: '15px',
                borderBottomRightRadius: '15px',
              },
            },
          }}
        >
          {children}
        </StatefulPopover>
      )}
    </>
  )
}

export default IndexPage
